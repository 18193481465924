import linkedin from './linkedin.png';
import email from './email.png';
import github from './github.png';
import twitter from './twitter.png';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  linkedin,
  email,
  github,
  twitter,
};
